<template>
  <div class="container-box">
    <div class="box-left" v-loading="loading" :element-loading-text="loadingText">
      <div class="guide-box">
        <span class="numBox">
          <span class="num1">1</span>
          <span class="num2">/</span>
          <span class="num2">2</span>
        </span>
        <span class="txt">你报考的信息是？</span>
        <span class="total">全国已参与估分人数：{{ rankCount }}人</span>
      </div>
      <div class="dark-border">
        <el-form ref="form" :model="formData" :rules="formRules" :inline="true" label-suffix="" size="small" class="form-box">
          <el-row class="title">一志愿报考信息</el-row>
          <el-row :gutter="10">
            <el-col :span="3">
              <el-form-item prop="universityProvince">
                <el-select v-model="formData.universityProvince" placeholder="省市" filterable @change="onSelectChange1(true)">
                  <el-option label="北京" value="北京"></el-option>
                  <el-option label="天津" value="天津"></el-option>
                  <el-option label="河北" value="河北"></el-option>
                  <el-option label="山西" value="山西"></el-option>
                  <el-option label="辽宁" value="辽宁"></el-option>
                  <el-option label="吉林" value="吉林"></el-option>
                  <el-option label="黑龙江" value="黑龙江"></el-option>
                  <el-option label="上海" value="上海"></el-option>
                  <el-option label="江苏" value="江苏"></el-option>
                  <el-option label="浙江" value="浙江"></el-option>
                  <el-option label="安徽" value="安徽"></el-option>
                  <el-option label="福建" value="福建"></el-option>
                  <el-option label="江西" value="江西"></el-option>
                  <el-option label="山东" value="山东"></el-option>
                  <el-option label="河南" value="河南"></el-option>
                  <el-option label="湖北" value="湖北"></el-option>
                  <el-option label="湖南" value="湖南"></el-option>
                  <el-option label="广东" value="广东"></el-option>
                  <el-option label="重庆" value="重庆"></el-option>
                  <el-option label="四川" value="四川"></el-option>
                  <el-option label="陕西" value="陕西"></el-option>
                  <el-option label="内蒙古" value="内蒙古"></el-option>
                  <el-option label="广西" value="广西"></el-option>
                  <el-option label="海南" value="海南"></el-option>
                  <el-option label="贵州" value="贵州"></el-option>
                  <el-option label="云南" value="云南"></el-option>
                  <el-option label="西藏" value="西藏"></el-option>
                  <el-option label="甘肃" value="甘肃"></el-option>
                  <el-option label="青海" value="青海"></el-option>
                  <el-option label="宁夏" value="宁夏"></el-option>
                  <el-option label="新疆" value="新疆"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item prop="universityCode">
                <el-select v-model="formData.universityCode" placeholder="学校" filterable @change="onSelectChange2(true)">
                  <el-option v-for="item in universityInfos" :label="item.UniversityName" :value="item.UniversityCode"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="collegeCode">
                <el-select v-model="formData.collegeCode" placeholder="学院" filterable @change="onSelectChange3(true)">
                  <el-option v-for="item in collegeInfos" :label="item.CollegeName" :value="item.CollegeCode"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="majorCode">
                <el-select v-model="formData.majorCode" placeholder="专业" filterable>
                  <el-option v-for="item in majorInfos" :label="`${item.MajorCode}${item.MajorName}`" :value="item.MajorCode"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item prop="majorLearningWay">
                <el-select v-model="formData.majorLearningWay" placeholder="学习方式" filterable>
                  <el-option label="全日制" value="全日制"></el-option>
                  <el-option label="非全日制" value="非全日制"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="title">初试成绩</el-row>
          <el-row :gutter="0">
            <el-col :span="5">
              <el-form-item label="政治" prop="politicalScore">
                <el-input-number v-model="formData.politicalScore" :min="0" :max="100" type="number" placeholder="政治" :controls="false" style="width: 80px;"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="外语" prop="englishScore">
                <el-input-number v-model="formData.englishScore" :min="0" :max="100" type="number" placeholder="外语" :controls="false" style="width: 80px;"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="专业一" prop="majorScore1">
                <el-input-number v-model="formData.majorScore1" :min="0" :max="300" type="number" placeholder="专业一" :controls="false" style="width: 80px;"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="专业二" prop="majorScore2">
                <el-input-number v-model="formData.majorScore2" :min="0" :max="300" type="number" placeholder="专业二" :controls="false" style="width: 80px;"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="总分">
                <el-input-number disabled :value="totalScore" type="number" placeholder="总分" :controls="false" style="width: 80px;"></el-input-number>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="title">学生信息</el-row>
          <el-row type="flex">
            <el-form-item label="姓名" prop="studentName">
              <el-input v-model="formData.studentName" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="studentPhone">
              <el-input v-model="formData.studentPhone" placeholder="电话"></el-input>
            </el-form-item>
            <!--  <el-form-item label="验证码" prop="studentCaptcha">
              <el-input v-model="formData.studentCaptcha" placeholder="验证码"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button :disabled="btnSendDisabled" type="primary" plain @click="onSendCaptcha">获取验证码</el-button>
            </el-form-item>-->
          </el-row>

          <el-form-item class="button">
            <el-button type="primary" @click="onQueryRank">查看排名情况</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="guide-box">
        <span class="numBox">
          <span class="num1">2</span>
          <span class="num2">/</span>
          <span class="num2">2</span>
        </span>
        <span class="txt-box">
          <span class="txt">你2025年的考研成绩排名：</span>
          <span class="txt">第</span>
          <span class="light"> {{ rankNumber }} </span>
          <span class="txt">名</span>
          <span class="space"></span>
          <span class="txt">统考计划招生人数：</span>
          <span class="txt">{{ majorPlannedEnrollment }}（不含推免）</span>
        </span>
      </div>
      <div v-if="rankInfos.length > 0">
        <div class="statement-txt">特别声明：成绩排名数据由用户自行填写提交，属于民间自发排名，成绩真实性无法核实，结果仅供参考，本数据与官方无关。</div>
        <el-table :data="rankInfos" size="small" border>
          <el-table-column align="center" label="排名" width="60" fixed>
            <template slot-scope="scope">{{ getRankNumber(scope) }}</template></el-table-column>
          <el-table-column align="center" prop="StudentName" label="姓名" width="100"></el-table-column>
          <el-table-column align="center" prop="StudentPhone" label="电话" width="100"></el-table-column>
          <el-table-column align="center" prop="UniversityName" label="学校名称" width="180"></el-table-column>
          <el-table-column align="center" prop="CollegeName" label="学院名称" width="180"></el-table-column>
          <el-table-column align="center" prop="MajorName" label="专业名称" width="180"></el-table-column>
          <el-table-column align="center" prop="MajorLearningWay" label="学习方式" width="100"></el-table-column>
          <el-table-column align="center" prop="PoliticalScore" label="政治" width="80"></el-table-column>
          <el-table-column align="center" prop="EnglishScore" label="外语" width="80"></el-table-column>
          <el-table-column align="center" prop="MajorScore1" label="专业一" width="80"></el-table-column>
          <el-table-column align="center" prop="MajorScore2" label="专业二" width="80"></el-table-column>
          <el-table-column align="center" prop="TotalScore" label="总分" width="80"></el-table-column>
          <!-- <el-table-column align="center" label="提交时间" width="150" :formatter="formatDate"></el-table-column> -->
        </el-table>
        <div class="pagination-box">
          <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
        </div>
        <header-entry>复试资料</header-entry>
        <div class="content-border content-subject">
          <div class="content-subject-item" v-if="documentInfos.length == 0">该科目资料暂未上传，请联系商家上架！</div>
          <template v-for="docuemntInfo in documentInfos">
            <div class="content-subject-item">
              <span class="content-subject-item-name" @click="onDocumentClick(docuemntInfo)">{{ docuemntInfo.DocumentName }}</span>
              <span class="content-subject-item-price">￥{{ docuemntInfo.DocumentPrice }}</span>
            </div>
          </template>
        </div>
        <header-entry>报录数据</header-entry>
        <div class="content-border content-subject">
          <div class="content-subject-item" v-if="admitInfos.length == 0">数据统计中，请及时关注本站。</div>
          <template v-for="admitInfo in admitInfos">
            <div class="content-subject-item">
              <span class="content-subject-item-name" @click="onAdmitClick(admitInfo)">{{ admitInfo.AdmitName }}</span>
              <span class="content-subject-item-price">数据会员</span>
            </div>
          </template>
        </div>
      </div>
      <div v-else class="dark-border">
        <div class="empty-txt">请正确填写报考信息，点“查看排名情况”获取排名数据。</div>
      </div>
    </div>
    <div class="box-right">
      <school-hot></school-hot>
    </div>
  </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import SchoolHot from "@/components/PC/SchoolHot";
import SearchControl from "@/components/PC/SearchControl";
import Config from "@/api/service";
import Tools from "@/tool";
import Manager from "@/manager";
export default {
  name: "PCModule",
  components: {
    HeaderEntry,
    SchoolHot,
    SearchControl,
  },
  data() {
    return {
      formData: {
        universityProvince: null,
        universityCode: null,
        collegeCode: null,
        majorCode: null,
        majorLearningWay: null,
        politicalScore: null,
        englishScore: null,
        majorScore1: null,
        majorScore2: null,
        studentName: null,
        studentPhone: null,
        studentCaptcha: null,
      },
      formRules: {
        universityProvince: [
          { required: true, message: '请选择省市', trigger: 'blur' }
        ],
        universityCode: [
          { required: true, message: '请选择学校', trigger: 'blur' }
        ],
        collegeCode: [
          { required: true, message: '请选择学院', trigger: 'blur' }
        ],
        majorCode: [
          { required: true, message: '请选择专业', trigger: 'blur' }
        ],
        majorLearningWay: [
          { required: true, message: '请选择学习方式', trigger: 'blur' }
        ],
        politicalScore: [
          { required: true, message: '请输入政治分数', trigger: 'blur' }
        ],
        englishScore: [
          { required: true, message: '请输入外语分数', trigger: 'blur' }
        ],
        majorScore1: [
          { required: true, message: '请输入专业一分数', trigger: 'blur' }
        ],
        majorScore2: [
          { required: true, message: '请输入专业二分数', trigger: 'blur' }
        ],
        studentName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        studentPhone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          {
            required: true,
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur',
          },
        ],
        studentCaptcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
      },
      loading: false,
      loadingText: null,
      btnSendDisabled: false,
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      universityInfos: [],
      collegeInfos: [],
      majorInfos: [],
      documentInfos: [],
      admitInfos: [],
      rankInfos: [],
      rankCount: 0,
      rankNumber: 0,
      majorPlannedEnrollment: 0,
    };
  },
  computed: {
    formatDate() {
      return function (row) {
        if (row) {
          return Tools.formatDate(Tools.convertDate(row.CreateTime), 'yyyy-MM-dd HH:mm')
        }
      }
    },
    totalScore() {
      var total = Number(this.formData.politicalScore) + Number(this.formData.englishScore) + Number(this.formData.majorScore1) + Number(this.formData.majorScore2);
      if (isNaN(total)) {
        return 0;
      } else {
        return total;
      }
    }
  },
  methods: {
    //获取排名序号
    getRankNumber(scope) {
      return (scope.$index + 1) + (this.pageIndex - 1) * this.pageSize;
    },
    //查询成绩排名总数
    getGradeRankCount() {
      var model = {};
      this.$axios
        .post(Config.gradeRank.count, model)
        .then((res) => {
          this.rankCount = res.data.Data.GradeRankCount;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //添加成绩排名记录
    addGradeRankInfo() {
      var model = {
        UniversityCode: this.formData.universityCode,
        CollegeCode: this.formData.collegeCode,
        MajorCode: this.formData.majorCode,
        MajorLearningWay: this.formData.majorLearningWay,
        PoliticalScore: this.formData.politicalScore,
        EnglishScore: this.formData.englishScore,
        MajorScore1: this.formData.majorScore1,
        MajorScore2: this.formData.majorScore2,
        StudentName: this.formData.studentName,
        StudentPhone: this.formData.studentPhone,
        StudentCaptcha: this.formData.studentCaptcha,
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        .post(Config.gradeRank.add, model)
        .then((res) => {

        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //查询成绩排名记录
    getGradeRankInfos() {
      var model = {
        UniversityProvince: this.formData.universityProvince,
        UniversityCode: this.formData.universityCode,
        CollegeCode: this.formData.collegeCode,
        MajorCode: this.formData.majorCode,
        MajorLearningWay: this.formData.majorLearningWay,
        PoliticalScore: this.formData.politicalScore,
        EnglishScore: this.formData.englishScore,
        MajorScore1: this.formData.majorScore1,
        MajorScore2: this.formData.majorScore2,
        StudentName: this.formData.studentName,
        StudentPhone: this.formData.studentPhone,
        StudentCaptcha: this.formData.studentCaptcha,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };
      this.$axios
        .post(Config.gradeRank.list, model)
        .then((res) => {
          this.rankInfos = res.data.Data.GradeRankInfos;
          this.rankNumber = res.data.Data.GradeRankNumber;
          this.totalCount = res.data.Data.TotalCount;
        })
        .catch((error) => {
          this.$message.error(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //获取关联文档列表
    getDocumentInfos() {
      var model = {
        UniversityCode: this.formData.universityCode,
        CollegeCode: this.formData.collegeCode,
        MajorCode: this.formData.majorCode,
      };
      return this.$axios
        .post(Config.gradeRank.related.secondDocumentList, model)
        .then((res) => {
          this.documentInfos = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取关联报录列表
    getAdmitInfos() {
      var model = {
        UniversityCode: this.formData.universityCode,
        CollegeCode: this.formData.collegeCode,
        MajorCode: this.formData.majorCode,
      };
      return this.$axios
        .post(Config.gradeRank.related.admitList, model)
        .then((res) => {
          this.admitInfos = res.data.Data.List;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //获取专业信息
    getMajorInfo() {
      var model = {
        UniversityCode: this.formData.universityCode,
        CollegeCode: this.formData.collegeCode,
        MajorCode: this.formData.majorCode,
        MajorLearningWay: this.formData.majorLearningWay,
      };
      this.$axios
        .post(Config.gradeRank.majorInfo, model)
        .then((res) => {
          this.majorPlannedEnrollment = res.data.Data.MajorPlannedEnrollment;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord("估分排名");
    },
    //添加访问学校记录
    addVisitSchoolRecord() {
      Manager.addVisitSchoolRecord({
        StudentPhone: this.formData.studentPhone,
        UniversityCode: this.formData.universityCode,
        CollegeCode: this.formData.collegeCode,
        MajorCode: this.formData.majorCode,
        RecordModule: 9,
      });
    },
    //选择省市改变
    onSelectChange1(clean) {
      if (this.formData.universityProvince) {
        if (clean) {
          this.formData.universityCode = null;
          this.formData.collegeCode = null;
          this.formData.majorCode = null;
          this.formData.majorLearningWay = null;
          this.universityInfos = [];
          this.collegeInfos = [];
          this.majorInfos = [];
        }
        var model = {
          UniversityProvince: this.formData.universityProvince
        };
        this.$axios
          .post(Config.gradeRank.filter.schoolList, model)
          .then((res) => {
            this.universityInfos = res.data.Data.UniversityInfos;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    //选择大学改变
    onSelectChange2(clean) {
      if (this.formData.universityCode) {
        if (clean) {
          this.formData.collegeCode = null;
          this.formData.majorCode = null;
          this.formData.majorLearningWay = null;
          this.collegeInfos = [];
          this.majorInfos = [];
        }
        var model = {
          UniversityCode: this.formData.universityCode
        };
        this.$axios
          .post(Config.gradeRank.filter.collegeList, model)
          .then((res) => {
            this.collegeInfos = res.data.Data.CollegeInfos;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    //选择学院改变
    onSelectChange3(clean) {
      if (this.formData.collegeCode) {
        if (clean) {
          this.formData.majorCode = null;
          this.formData.majorLearningWay = null;
          this.majorInfos = [];
        }
        var model = {
          UniversityCode: this.formData.universityCode,
          CollegeCode: this.formData.collegeCode
        };
        this.$axios
          .post(Config.gradeRank.filter.majorList, model)
          .then((res) => {
            this.majorInfos = res.data.Data.MajorInfos;
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    //点击发送验证码
    onSendCaptcha() {
      if (!Tools.checkPhone(this.formData.studentPhone)) {
        this.$message.warning("请输入正确的手机号码。");
      } else {
        //发送验证码
        var model = {
          Phone: this.formData.studentPhone,
        };
        this.$axios
          .post(Config.sms.sendCommonMessage, model)
          .then((res) => {
            this.btnSendDisabled = true;
            this.$message.success("验证码发送成功。");
          })
          .catch((error) => {
            this.$message.error(error.message);
          });
      }
    },
    //点击查看排名情况
    onQueryRank() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingText = '正在查询...';
          this.loading = true;
          this.pageIndex = 1;
          this.totalCount = 0;
          this.addVisitSchoolRecord();
          this.addGradeRankInfo().then(() => {
            this.getGradeRankInfos();
            this.getDocumentInfos();
            this.getAdmitInfos();
            this.getMajorInfo();
          });
        }
      });
    },
    //点击资料
    onDocumentClick(documentInfo) {
      window.open(this.$router.resolve({
        name: 'Document1',
        params: {
          proxyCode: this.$store.state.proxyCode,
          documentGuid: documentInfo.DocumentGuid
        }
      }).href, '_blank')
    },
    //点击报录数据
    onAdmitClick(admitInfo) {
      window.open(this.$router.resolve({
        name: "Admit1",
        params: {
          proxyCode: this.$store.state.proxyCode,
          universityCode: this.formData.universityCode,
          collegeCode: this.formData.collegeCode,
          majorCode: this.formData.majorCode,
          majorLearnway: this.formData.majorLearningWay,
        }
      }).href, '_blank')
    },
    //点击分页
    onCurrentChange() {
      this.getGradeRankInfos();
    },
  },
  mounted() {
    this.addVisitProductRecord();
    this.getGradeRankCount();
  },
};
</script>
<style scoped lang="less">
.container-box {
  display: flex;
  flex-direction: row;
}

.box-left {
  flex: 1;
  overflow: hidden;
}

.box-right {
  width: 300px;
  margin: 0 0 0 50px;
}

.dark-border {
  border: 1px solid #e6e6e6;
}

.bottom-line {
  border-top: 1px solid #e6e6e6;
  margin: 10px 0;
}

.form-box {
  padding: 0px 10px;

  .title {
    font-size: 14px;
    font-weight: 700;
    margin: 10px 0;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
  }
}

.pagination-box {
  text-align: center;
  margin: 10px;
}

.guide-box {
  display: flex;
  align-items: flex-end;
  margin: 20px 0;
  font-size: 18px;

  .numBox {
    display: inline;
    letter-spacing: 5px;

    .num1 {
      color: #ff6602;
      font-size: 20px;
      font-weight: 700;
    }

    .num2 {
      color: gray;
      font-size: 16px;
    }
  }

  .txt-box {
    margin-left: 10px;
    white-space: pre;

    .txt {
      color: black;
    }

    .light {
      color: #FF6602;
      font-weight: bold;
    }

    .space {
      margin-left: 40px;
    }
  }


  .total {
    margin-left: auto;
    font-size: 14px;
    font-weight: bold;
  }
}


.content-border {
  border: 1px solid #e6e6e6;
  padding: 10px 30px;
}

.content-major {
  margin: 10px 0;
}

.content-subject {
  margin: 10px 0;
}

.content-subject-item {
  display: flex;
  margin: 20px 0;

  .content-subject-item-name {
    flex: 1;
    margin-right: 20px;
    cursor: pointer;
    color: #555;
    font-size: 14px;
  }

  .content-subject-item-name:hover {
    color: #FE5E03;
  }

  .content-subject-item-price {
    min-width: 40px;
    color: #555;
    font-size: 14px;
  }
}

.empty-txt {
  padding: 10px;
  color: gray;
  font-size: 14px;
}

.statement-txt {
  margin-bottom: 10px;
  color: gray;
  font-size: 14px;
}

::v-deep .el-form-item {
  white-space: nowrap;
}
</style>